import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import * as styles from "./legalsLink.module.scss";
import { usePageContext } from "../context/PageContext";
import { LegalsLinkQuery } from "../../../graphql-types";

export const LegalsLink = () => {
  const { lang } = usePageContext();
  const { sanityFooter } = useStaticQuery<LegalsLinkQuery>(QUERY);
  return (
    <Link to="/legals" className={styles.link}>
      {sanityFooter?._rawLegalsLabel?.[lang]}
    </Link>
  );
};

const QUERY = graphql`
  query LegalsLink {
    sanityFooter {
      _rawLegalsLabel
    }
  }
`;
