import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { StyledButton, LinkButtonColor } from "../styled/StyledButton";
import { usePageContext } from "../context/PageContext";
import { FooterQuery, SanityButton } from "../../../graphql-types";
import { Socials } from "../socials/Socials";
import { GatsbyImage } from "gatsby-plugin-image";
import { PartnersLogos } from "../logos/PartnersLogos";
import { LegalsLink } from "../mobileMenu/LegalsLink";
import { Accordion, AccordionItem } from "../accordion/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import BlockContent from "@sanity/block-content-to-react";
import * as styles from "./footer.module.scss";

export const Footer = () => {
  const { lang } = usePageContext();
  const { sanityFooter } = useStaticQuery<FooterQuery>(QUERY);

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttonsWrapper}>
        {sanityFooter?.buttons?.map(item => (
          <div key={item?._key}>
            <StyledButton
              color={LinkButtonColor.black}
              button={item as SanityButton}
            />
          </div>
        ))}
      </div>
      <div className={styles.addressDesktop}>
        <p className={styles.title}>{sanityFooter?._rawAddressLabel?.[lang]}</p>
        <BlockContent blocks={sanityFooter?._rawAddress?.[lang]} />
      </div>
      <div className={styles.accessDesktop}>
        <p className={styles.title}>{sanityFooter?._rawAccessLabel?.[lang]}</p>
        {sanityFooter?.footerItems?.map(item => (
          <Link key={item?._key} to={item?.path as string}>
            <p>{item?._rawLabel?.[lang]}</p>
          </Link>
        ))}
      </div>
      <div className={styles.accordionWrapper}>
        <Accordion>
          <AccordionItem
            title={sanityFooter?._rawAddressLabel?.[lang]}
            id={"footerAccordion"}
            key={"footer_0_item"}
            icon={<FontAwesomeIcon icon={faChevronDown} />}
          >
            <BlockContent blocks={sanityFooter?._rawAddress?.[lang]} />
          </AccordionItem>
          <AccordionItem
            title={sanityFooter?._rawAccessLabel?.[lang]}
            id={"footerAccordion"}
            key={"footer_1_item"}
            icon={<FontAwesomeIcon icon={faChevronDown} />}
          >
            {sanityFooter?.footerItems?.map(item => (
              <Link key={item?._key} to={item?.path as string}>
                <p>{item?._rawLabel?.[lang]}</p>
              </Link>
            ))}
          </AccordionItem>
        </Accordion>
      </div>
      <div className={styles.socialsWrapper}>
        <Socials />
      </div>
      <div className={styles.logosWrapper}>
        <PartnersLogos />
      </div>
      <div className={styles.legalsWrapper}>
        <LegalsLink />
      </div>
    </div>
  );
};

const QUERY = graphql`
  query Footer {
    sanityFooter {
      _rawAddress
      buttons {
        target
        url
        _rawLabel
        _key
      }
      footerItems {
        _rawLabel
        path
        _key
      }
      _rawLegalsLabel
      _rawAddressLabel
      _rawAccessLabel
    }
  }
`;
