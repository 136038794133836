import React, { useState } from "react"
import * as styles from "./burgerMenuIcon.module.scss"

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
}
export const BurgerMenuIcon: React.FC<Props> = ({ open, setOpen }) => {
  return (
    <div className={styles.burgerIcon} onClick={() => setOpen(!open)}>
      <span className={open ? styles.opened : styles.closed} />
    </div>
  )
}
