import React from "react";
import * as styles from "./partnersLogos.module.scss";
import { graphql, useStaticQuery } from "gatsby";
import { PartnersLogosQuery } from "../../../graphql-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { usePageContext } from "../context/PageContext";

export const PartnersLogos = () => {
  const { sanityFooter } = useStaticQuery<PartnersLogosQuery>(QUERY);
  const { lang } = usePageContext();

  return (
    <div className={styles.wrapper}>
      {sanityFooter?.logos?.map(logo => (
        <GatsbyImage
          image={logo?.image?.asset?.gatsbyImageData}
          alt={logo?._rawAlt?.[lang]}
          objectFit="contain"
          key={logo?._key}
        />
      ))}
    </div>
  );
};

const QUERY = graphql`
  query partnersLogos {
    sanityFooter {
      logos {
        _key
        _rawAlt
        image {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
