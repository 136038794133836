import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import * as styles from "./mobileMenu.module.scss";
import { MenuLinksQuery, SanityMenuItem } from "../../../graphql-types";
import { usePageContext } from "../context/PageContext";
import { Socials } from "../socials/Socials";
import { PartnersLogos } from "../logos/PartnersLogos";
import { LegalsLink } from "./LegalsLink";
import { StyledLink } from "../styled/StyledLink";

export const MobileMenu = () => {
  const { sanityMenu } = useStaticQuery<MenuLinksQuery>(MENULINKSQUERY);
  const { lang } = usePageContext();

  return (
    <div className={styles.wrapper}>
      <nav className={styles.linksWrapper}>
        {sanityMenu?.items?.map(item => (
          <StyledLink path={item?.path as string}>
            {item?._rawLabel?.[lang]}
          </StyledLink>
        ))}
      </nav>
      <div className={styles.footer}>
        <Socials />
        <PartnersLogos />
        <LegalsLink />
      </div>
    </div>
  );
};

export const MENULINKSQUERY = graphql`
  query menuLinks {
    sanityMenu {
      items {
        _rawLabel
        path
      }
    }
  }
`;
