import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { StyledButton, LinkButtonColor } from "../styled/StyledButton";
import { usePageContext } from "../context/PageContext";
import { FooterQuery, SanityButton } from "../../../graphql-types";
import { Socials } from "../socials/Socials";
import { PartnersLogos } from "../logos/PartnersLogos";
import { LegalsLink } from "../mobileMenu/LegalsLink";
import BlockContent from "@sanity/block-content-to-react";
import * as styles from "./footerDesktop.module.scss";

export const FooterDesktop = () => {
  const { lang } = usePageContext();
  const { sanityFooter } = useStaticQuery<FooterQuery>(QUERY);

  return (
    <div className={styles.background}>
      <div className={styles.wrapper}>
        <div className={styles.addressDesktop}>
          <p className={styles.title}>
            {sanityFooter?._rawAddressLabel?.[lang]}
          </p>
          <BlockContent blocks={sanityFooter?._rawAddress?.[lang]} />
        </div>
        <div className={styles.accessDesktop}>
          <p className={styles.title}>
            {sanityFooter?._rawAccessLabel?.[lang]}
          </p>
          {sanityFooter?.footerItems?.map(item => (
            <Link key={item?._key} to={item?.path as string}>
              <p>{item?._rawLabel?.[lang]}</p>
            </Link>
          ))}
        </div>

        <div className={styles.lastCol}>
          <div className={styles.buttonsWrapper}>
            {sanityFooter?.buttons?.map(item => (
              <div key={item?._key}>
                <StyledButton
                  color={LinkButtonColor.black}
                  button={item as SanityButton}
                />
              </div>
            ))}
          </div>
          <div className={styles.flexRow}>
            <div className={styles.logosWrapper}>
              <PartnersLogos />
            </div>
            <div className={styles.flexCol}>
              <div className={styles.socialsWrapper}>
                <Socials />
              </div>
              <div className={styles.legalsWrapper}>
                <LegalsLink />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const QUERY = graphql`
  query FooterAgain {
    sanityFooter {
      _rawAddress
      buttons {
        target
        url
        _rawLabel
        _key
      }
      footerItems {
        _rawLabel
        path
        _key
      }
      _rawLegalsLabel
      _rawAddressLabel
      _rawAccessLabel
    }
  }
`;
