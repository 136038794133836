import { Link } from "gatsby";
import React, { HTMLAttributeAnchorTarget } from "react";
import { usePageContext } from "../context/PageContext";

interface StyledLinkProps {
  path: string;
  target?: HTMLAttributeAnchorTarget;
  className?: string;
  color?: string;
  disabled?: boolean;
}

export const StyledLink: React.FC<StyledLinkProps> = ({
  path,
  target = "_self",
  children,
  className,
  color,
  disabled = false,
}) => {
  const { lang, defaultLang } = usePageContext();
  const isExternal = path.indexOf("/") === 0 ? false : true;
  const localePath = `${lang !== defaultLang ? `/${lang}${path}` : path}`;

  if (disabled) return <>{children}</>;

  return (
    <>
      {isExternal ? (
        <a
          className={`${className}`}
          href={path}
          target={target}
          style={{ color }}
        >
          {children}
        </a>
      ) : (
        <Link className={`${className}`} to={localePath} style={{ color }}>
          {children}
        </Link>
      )}
    </>
  );
};
