import React, { useEffect } from "react";
import * as styles from "./accordion.module.scss";

export interface AccordionProps {
  children: React.ReactNode;
}

export interface AccordionItemProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
  index?: number | string;
  icon: React.ReactNode;
  id?: string;
  border?: boolean;
  color?: string;
  open?: boolean;
  bgColor?: boolean;
  colorOnOpen?: string;
  classNameTitle?: string;
  classNameChild?: string;
}

export const Accordion: React.FC<AccordionProps> = ({ children }) => {
  const node = React.Children.map(children, (child, index) => {
    {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          index: index,
        });
      }
      return child;
    }
  });
  return <div>{node}</div>;
};

export const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  children,
  icon,
  index,
  id = "",
  border,
  color = "light",
  bgColor = true,
  open = false,
  colorOnOpen,
  classNameTitle = "",
  classNameChild = "",
}) => {
  const colorHeader =
    color === "light"
      ? styles.light
      : color === "pink"
      ? styles.pink
      : color === "red"
      ? styles.red
      : styles.white;
  const colorOpen = !colorOnOpen
    ? colorHeader
    : colorOnOpen === "light"
    ? styles.light
    : colorOnOpen === "pink"
    ? styles.pink
    : colorOnOpen === "red"
    ? styles.red
    : styles.white;

  const handleClick = () => {
    const elem = document.getElementById(`${index + id}`);
    const icon = document.getElementById(`${index + id}-icon`);
    const header = document.getElementById(`${index + id}-header`);
    const accordionItems = [...document.getElementsByClassName(styles.content)];
    accordionItems.forEach(element => {
      if (element !== elem?.childNodes[1]) {
        element.style.removeProperty("max-height");
      }
    });
    const accordionIcon = [...document.getElementsByClassName(styles.icon)];
    accordionIcon.forEach(element => {
      if (element !== icon) {
        element.style.transform = "rotate(0turn)";
      }
    });
    const accordionHeader = [...document.getElementsByClassName(styles.header)];
    accordionHeader.forEach(element => {
      if (element !== header) {
        element.classList.remove(colorOpen);
      }
    });
    if (elem) {
      const content = elem.childNodes[1] as HTMLElement;
      if (icon) {
        if (icon.style.transform !== "rotate(0.5turn)") {
          icon.style.transform = "rotate(0.5turn)";
        } else {
          icon.style.transform = "rotate(0turn)";
        }
      }
      if (content.style.maxHeight === "") {
        content.style.maxHeight = content.scrollHeight + "px";
      } else {
        content.style.removeProperty("max-height");
      }
    }
    if (header) {
      if (header.classList.contains(colorOpen)) {
        header.classList.remove(colorOpen);
      } else {
        header.classList.add(colorOpen);
      }
    }
  };

  useEffect(() => {
    if (open) handleClick();
  }, [open]);

  return (
    <div id={`${index + id}`}>
      <div
        id={`${index + id}-header`}
        className={`${styles.header} ${
          border ? styles.border : ""
        } ${colorHeader} ${
          !bgColor ? styles.transparent : ""
        } ${classNameTitle}`}
        onClick={() => handleClick()}
      >
        <div>{title}</div>
        <div id={`${index + id}-icon`} className={styles.icon}>
          {icon}
        </div>
      </div>
      <div
        className={`${styles.content} ${
          !bgColor ? styles.transparent : ""
        } ${classNameChild}`}
      >
        {children}
      </div>
    </div>
  );
};
