import { graphql, Link } from "gatsby";
import { useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { HeaderItemsQuery } from "../../../graphql-types";
import { BurgerMenuIcon } from "../burgerMenuIcon/BurgerMenuIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as styles from "./header.module.scss";
import { faHeart } from "@fortawesome/pro-light-svg-icons";
import { faHeart as faHeartFull } from "@fortawesome/pro-solid-svg-icons";
import { LangSelector } from "../langSelector/LangSelector";
import { MobileMenu } from "../mobileMenu/MobileMenu";
import { usePageContext } from "../context/PageContext";
import { StyledLink } from "../styled/StyledLink";
import { SiteLogo } from "./SiteLogo";
import { useEffect } from "react";

export const Header: React.FC = React.memo(() => {
  const { sanityMenu, sanitySettings } =
    useStaticQuery<HeaderItemsQuery>(QUERY);
  const { lang, originalPath, favorites } = usePageContext();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className={styles.wrapper}>
      <div className={styles.containerBg}>
        <BurgerMenuIcon open={open} setOpen={setOpen} />
        {/* <div className={styles.logoWrapper}> */}
        <nav>
          <SiteLogo />
          <div className={styles.menuDesktopButtons}>
            {sanityMenu?.items?.map((item, index) => (
              <StyledLink
                path={item?.path as string}
                className={`${styles.button} ${
                  originalPath?.replaceAll("/", "") ===
                  item?.path?.replaceAll("/", "")
                    ? styles.buttonSelected
                    : ""
                }`}
                key={`${index}-navMenu`}
              >
                {item?._rawLabel?.[lang]}
              </StyledLink>
            ))}
          </div>
        </nav>
        <div className={styles.redButton}>
          <StyledLink path={"/devis"}>
            {sanitySettings?.main?._rawDevisButton?.[lang]}
          </StyledLink>
        </div>
        <StyledLink path={"/favorites"} className={styles.icon}>
          <FontAwesomeIcon
            icon={favorites.length > 0 ? faHeartFull : faHeart}
            size="1x"
          />
        </StyledLink>
        <div className={styles.separator} />
        <LangSelector />
      </div>
      {open && <MobileMenu />}
    </div>
  );
});

const QUERY = graphql`
  query HeaderItems {
    sanityMenu {
      items {
        _rawLabel
        path
      }
    }
    sanitySettings {
      main {
        _rawDevisButton
      }
    }
  }
`;
