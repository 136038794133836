import "@fontsource/titillium-web";
import "@fontsource/titillium-web/700.css";
// import "@fontsource/titillium-web/800.css"
import "@fontsource/titillium-web/900.css";
import "./src/styles/globals.scss";
import React from "react";
import { Layout } from "./src/components/layout/Layout";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";

import { PageContextProvider } from "./src/components/context/PageContext";

config.autoAddCss = false;

export const wrapPageElement = ({ element, props }) => {
  return (
    <PageContextProvider {...props}>
      <Layout>{element}</Layout>
    </PageContextProvider>
  );
};

/** The SideBarBtns class */
class SideBarBtns {
  /** Used to register the scroll event handler */
  Initialize() {
    /** When the user scrolls down 300px from the top of the document, show the buttons */
    window.addEventListener("scroll", this.ToggleButtons);
    /** Event handler for scroll to top button */
    document
      .getElementById("scroll-btn")
      .addEventListener("click", this.ScrollToTop);
  }

  /** Displays/Hides the buttons */
  ToggleButtons() {
    /** If the current current scroll is 300px or more */
    if (
      (document.body.scrollTop > 300 ||
        document.documentElement.scrollTop > 300) &&
      document.getElementById("scroll-btn")
    ) {
      /** The scroll to top button is displayed */
      document.getElementById("scroll-btn").style.display = "flex";
    } else if (document.getElementById("scroll-btn")) {
      /** The scroll to top button is hidden */
      document.getElementById("scroll-btn").style.display = "none";
    }
  }

  /** When the user clicks on the button, scroll to the top of the document */
  ScrollToTop() {
    /** The user is scrolled to the top of the page */
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
}

export const onRouteUpdate = () => {
  let sidebarbtns = new SideBarBtns();
  if (document.getElementById("scroll-btn")) {
    sidebarbtns.Initialize();
  }
};
