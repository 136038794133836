import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { SocialsQuery } from "../../../graphql-types";
import * as styles from "./socials.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName, library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebook,
  faFacebookF,
  faInstagram,
  faLinkedin,
  faLinkedinIn,
  faPinterest,
  faSnapchat,
  faSnapchatGhost,
  faTwitch,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faFacebook,
  faFacebookF,
  faYoutube,
  faTwitter,
  faTwitch,
  faInstagram,
  faLinkedin,
  faLinkedinIn,
  faSnapchat,
  faSnapchatGhost,
  faPinterest
);

export const Socials = () => {
  const { sanitySettings } = useStaticQuery<SocialsQuery>(QUERY);

  return (
    <div className={styles.wrapper}>
      {sanitySettings?.socials?.map((social, index: number) => (
        <a
          target={"_blank"}
          href={social?.url as string}
          key={`footer-social-${index}`}
          className={styles.icon}
        >
          <FontAwesomeIcon
            className={styles.icon}
            icon={["fab", social?.icon as IconName]}
          />
        </a>
      ))}
    </div>
  );
};

const QUERY = graphql`
  query Socials {
    sanitySettings {
      socials {
        icon
        url
      }
    }
  }
`;
