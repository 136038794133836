import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { StyledLink } from "../styled/StyledLink";
import { SiteLogoQuery } from "../../../graphql-types";
import * as styles from "./header.module.scss";

export const SiteLogo = React.memo(() => {
  const { sanitySettings } = useStaticQuery<SiteLogoQuery>(QUERY);

  return (
    <StyledLink path="/">
      <GatsbyImage
        image={sanitySettings?.main?.logo?.asset?.gatsbyImageData}
        alt={"Tours Convention-Bureau"}
        className={styles.siteLogo}
        objectFit={"cover"}
      />
    </StyledLink>
  );
});

const QUERY = graphql`
  query SiteLogo {
    sanitySettings {
      main {
        logo {
          asset {
            gatsbyImageData(placeholder: NONE)
          }
        }
      }
    }
  }
`;
