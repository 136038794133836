import { graphql, navigate, useStaticQuery } from "gatsby";
import React from "react";
import { SiteLangsQuery } from "../../../graphql-types";
import { usePageContext } from "../context/PageContext";
import * as styles from "./langSelector.module.scss";

export const LangSelector: React.FC = () => {
  const { lang, setLang, originalPath, defaultLang } = usePageContext();
  const { site } = useStaticQuery<SiteLangsQuery>(QUERY);

  const langs = site?.siteMetadata?.extraLanguages?.concat(
    site?.siteMetadata.defaultLanguage
  ) as string[];

  return (
    <select
      className={styles.wrapper}
      name="lang"
      onChange={e => {
        setLang(e.target.value);
        navigate(
          e.target.value === defaultLang
            ? originalPath
            : `/${e.target.value}${originalPath}`
        );
      }}
    >
      <option value={lang}>{lang.toUpperCase()}</option>
      {langs.map(
        (langItem: string) =>
          lang !== langItem && (
            <option key={langItem} value={langItem}>
              {langItem.toUpperCase()}
            </option>
          )
      )}
    </select>
  );
};

const QUERY = graphql`
  query siteLangs {
    site {
      siteMetadata {
        defaultLanguage
        extraLanguages
      }
    }
  }
`;
