import React, { createContext, useContext, useState } from "react";
import {
  SanityLocaleString,
  SanityLocaleText,
  OtLibelle,
  Ot,
} from "../../../graphql-types";
import { useLocalStorage } from "../../hooks/useLocalStorage";
// import { useCookie } from "../../hooks/useCookie";
// import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useWindowSize } from "../../hooks/useWindowSize";
import { OtDescriptifsContenu } from "../../../graphql-types";

interface Dimensions {
  width: number;
  height: number;
}

interface IContext {
  lang: keyof SanityLocaleString &
    keyof SanityLocaleText &
    keyof OtLibelle &
    keyof OtDescriptifsContenu &
    string;
  defaultLang: string;
  originalPath: string;
  setLang: (lang: string) => any;
  favorites: Ot[];
  toggleFavorite: (ot: Ot) => void;
  setFavorites: (ots: Ot[]) => void;
  // size: Dimensions
}

const PageContext = createContext<IContext | undefined>(undefined);
const defaultLang = "fr";

export const PageContextProvider: React.FC<{
  pageContext: any;
  children: any;
}> = ({ pageContext, children }) => {
  const [lang, setLang] = useState<string>(pageContext.lang);
  const [favorites, setFavorites] = useLocalStorage("favorites", []);

  const toggleFavorite = (ot: Ot) => {
    if (favorites && favorites.length > 0) {
      if (favorites.filter((item: any) => item.id === ot.id).length) {
        const newFavorites = favorites.filter((item: any) => item.id !== ot.id);
        setFavorites([...newFavorites]);
      } else {
        setFavorites([...favorites, ot]);
      }
    } else {
      setFavorites([ot]);
    }
  };

  // const [cookie, updateCookie, removeCookie, lengthCookie] = useCookie(
  //   "default",
  //   [],
  //   {
  //     maxAge: 1296000000,
  //     path: "/",
  //   }
  // );
  // const size = useWindowSize()
  return (
    <PageContext.Provider
      value={{
        ...pageContext,
        lang: lang,
        setLang: setLang,
        defaultLang,
        favorites,
        toggleFavorite,
        setFavorites,
        // cookie: cookie,
        // updateCookie: updateCookie,
        // removeCookie: removeCookie,
        // lengthCookie: lengthCookie,
        // size: size,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => {
  const pageContext = useContext(PageContext);
  if (!pageContext)
    throw new Error(
      "No PageContext.Provider found when calling usePageContext"
    );
  return pageContext;
};
