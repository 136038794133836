module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tours Val de Loire Convention Bureau","short_name":"Tours Convention Bureau","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icons":[{"src":"/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../plugins/gatsby-plugin-ingenie/gatsby-browser.js'),
      options: {"plugins":[],"username":"soixanteseize","password":"@d2gNp2d$f","organisme":"533","url":"https://api.ingenie.fr/login","sanity_token":"skTT9d2XJutO8uSX1nf1eCoYLwukCouhWTosLJu1z8kkd44Nglst1qPKTpz0pOjtlXESzTa4Oh7IQnF6FJznnkzqGgaR1lCNz0ly5tCelcww1GPtjmcaLbjBszt96fpGC0xyFyERyUmIz3F3tYVuQe3lCmibv8U9a85KQuNJSRwJcf2gKiVg","sanity_url":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-88262525-1"],"pluginConfig":{"head":true,"respectDNT":true,"exclude":[]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
