import React from "react";
import { SanityButton, SanityProButton } from "../../../graphql-types";
import { StyledLink } from "./StyledLink";
import * as styles from "./styledButton.module.scss";
import { usePageContext } from "../context/PageContext";

export enum LinkButtonColor {
  white = "white",
  red = "red",
  black = "black",
}

interface Props {
  button?: SanityButton | SanityProButton;
  url?: string;
  onClick?: () => void;
  label?: string;
  color: LinkButtonColor;
  className?: string;
}

export const StyledButton: React.FC<Props> = ({
  button = undefined,
  color = "",
  url = "",
  label = "",
  className = "",
  onClick = undefined,
}) => {
  const { lang } = usePageContext();

  return (
    <div
      className={`${styles[color as any]} ${styles.wrapper} ${className}`}
      onClick={onClick}
    >
      {button &&
        (button._type === "proButton" ? (
          <StyledLink path={button.url ?? ""} target={button.target ?? ""}>
            {button.label}
          </StyledLink>
        ) : (
          <StyledLink path={button.url ?? ""} target={button.target ?? ""}>
            {button.label?.[lang] || button._rawLabel?.[lang]}
          </StyledLink>
        ))}
      {url && label && <StyledLink path={url}>{label}</StyledLink>}
      {onClick && label}
    </div>
  );
};
