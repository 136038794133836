// extracted by mini-css-extract-plugin
export var accessDesktop = "footerDesktop-module--accessDesktop--dadc8";
export var accordionWrapper = "footerDesktop-module--accordionWrapper--492a3";
export var addressDesktop = "footerDesktop-module--addressDesktop--20053";
export var background = "footerDesktop-module--background--d3926";
export var buttonsWrapper = "footerDesktop-module--buttonsWrapper--335e8";
export var flexCol = "footerDesktop-module--flexCol--a4525";
export var flexRow = "footerDesktop-module--flexRow--d70f5";
export var legalsWrapper = "footerDesktop-module--legalsWrapper--620ec";
export var logosWrapper = "footerDesktop-module--logosWrapper--aaa8d";
export var socialsWrapper = "footerDesktop-module--socialsWrapper--94fd7";
export var title = "footerDesktop-module--title--db3d5";
export var wrapper = "footerDesktop-module--wrapper--2523c";