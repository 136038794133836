import React from "react";
import * as styles from "./layout.module.scss";
import { Footer } from "./Footer";
import { Header } from "../header/Header";
import { FooterDesktop } from "./FooterDesktop";
import { NewsBanner } from "./NewsBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/pro-regular-svg-icons";

export const Layout: React.FC = ({ children }) => (
  <div className={styles.wrapper}>
    <Header />
    <main className={styles.grow}>{children}</main>
    <NewsBanner />
    <Footer />
    <FooterDesktop />
    <button id="scroll-btn" className="sidebar-btns" title="Scroll to top">
      <FontAwesomeIcon icon={faChevronUp} color={"white"} />
    </button>
  </div>
);
