import React from "react";
import { usePageContext } from "../context/PageContext";
import * as styles from "./newsBanner.module.scss";
import { graphql, useStaticQuery } from "gatsby";
import { NewsletterQuery } from "../../../graphql-types";
import { StyledButton, LinkButtonColor } from "../styled/StyledButton";

export const NewsBanner = () => {
  const { lang } = usePageContext();
  const { sanitySettings } = useStaticQuery<NewsletterQuery>(QUERY);
  return (
    <div className={styles.background}>
      <div className={styles.wrapper}>
        <div>
          <h2>{sanitySettings.newsletter._rawTitle?.[lang]}</h2>
          <p>{sanitySettings.newsletter._rawSubtitle?.[lang]}</p>
        </div>
        <StyledButton
          url="/newsletter"
          color={LinkButtonColor.white}
          label={sanitySettings.newsletter._rawCta?.[lang]}
          className={styles.button}
        />
      </div>
    </div>
  );
};

const QUERY = graphql`
  query Newsletter {
    sanitySettings {
      newsletter {
        _rawCta
        _rawSubtitle
        _rawTitle
      }
    }
  }
`;
